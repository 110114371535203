import { Injectable } from '@angular/core';
import { from, mergeMap, Observable } from 'rxjs';
import { LanguageService } from './language.service';
import { GlobalConfigService } from './global-config.service';
import StorageHelper from '../helpers/Storage.helper';
import { SSOService } from '../sso/sso.service';
import { UserService } from './user.service';

@Injectable()
export class AppInitializer {
  // prettier-ignore
  constructor(
    private languageService: LanguageService,
    private globalConfigService: GlobalConfigService,
    private ssoService: SSOService,
    private userService: UserService,
  ) {}

  initialize(): Observable<any> {
    StorageHelper.clearLanguages();
    return from(this.ssoService.runInitialLoginSequence()).pipe(
      mergeMap(() => this.userService.getUserInfo()),
      mergeMap(() => this.languageService.initializeLocalTranslations()),
      mergeMap(() => this.globalConfigService.initializeGlobalConfig()),
      mergeMap(() => this.languageService.refreshLanguages())
    );
    // return this.languageService
    //   .initializeLocalTranslations()
    //   .pipe(
    //     mergeMap(() => this.globalConfigService.initializeGlobalConfig().pipe(mergeMap(() => this.languageService.refreshLanguages())))
    //   );
  }
}
