import { Injectable } from '@angular/core';
import { GlobalConfigService } from '../services/global-config.service';
import { NavigationEnd, Router } from '@angular/router';
import { EntityDetailsRoutes, EntityDetailsTabRoutes } from '../constants/Global/AnalyticsTracking';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(private router: Router, private globalConfigSvc: GlobalConfigService) {}

  initialize() {
    this.onRouteChange();

    try {
      const trackingCode = this.globalConfigSvc.globalConfig.googleAnalyticsTrackingCode;
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${trackingCode}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${trackingCode}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  private onRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const detailsRoute = EntityDetailsRoutes.find((route) => event.urlAfterRedirects.indexOf(route) !== -1);
        const detailsTabRoute = EntityDetailsTabRoutes.find((tabRoute) => event.urlAfterRedirects.indexOf(tabRoute) !== -1);
        if (!(detailsRoute && detailsTabRoute)) {
          const trackingCode = this.globalConfigSvc.globalConfig.googleAnalyticsTrackingCode;
          gtag('config', `'${trackingCode}'`, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
  }

  public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(eventLabel && { event_label: eventLabel }),
      ...(value && { value: value }),
    });
  }
}
