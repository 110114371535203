export const ToastrMessages = {
  BASIC_ERROR: ['NOTIFICATIONS.GENERAL.BASIC_ERROR'],
  USER_PROFILE_ERROR: ['NOTIFICATIONS.AUTH.USER_PROFILE_ERROR'],
  EXPIRED_SESSION: ['NOTIFICATIONS.AUTH.EXPIRED_SESSION', 'NOTIFICATIONS.TITLES.EXPIRED_SESSION'],
  NOT_FOUND: ['NOTIFICATIONS.AUTH.NOT_FOUND'],

  ACCOUNT_CREATED: ['NOTIFICATIONS.AUTH.ACCOUNT_CREATED'],
  FORGOT_PASSWORD: ['NOTIFICATIONS.AUTH.FORGOT_PASSWORD'],
  FORGOT_PASSWORD_ADMIN: ['NOTIFICATIONS.AUTH.FORGOT_PASSWORD_ADMIN'],
  RESET_PASSWORD: ['NOTIFICATIONS.AUTH.RESET_PASSWORD'],
  CAPTCHA_INVALID: ['NOTIFICATIONS.AUTH.CAPTCHA_INVALID'],
  TOKEN_EXPIRED: ['NOTIFICATIONS.AUTH.TOKEN_EXPIRED'],
  EMAIL_USED: ['NOTIFICATIONS.AUTH.EMAIL_USED'],
  EMAIL_RESENT: ['NOTIFICATIONS.AUTH.EMAIL_RESENT'],
  USER_NOT_FOUND: ['NOTIFICATIONS.AUTH.USER_NOT_FOUND'],
  USER_UNVERIFIED: ['NOTIFICATIONS.AUTH.USER_UNVERIFIED'],
  USER_WAS_VETTED: ['NOTIFICATIONS.AUTH.USER_WAS_VETTED'],
  USER_WAS_UNVETTED: ['NOTIFICATIONS.AUTH.USER_WAS_UNVETTED'],
  BAD_CREDENTIALS: ['NOTIFICATIONS.AUTH.BAD_CREDENTIALS'],
  NEW_PASSWORD_SAME_AS_OLD: ['NOTIFICATIONS.AUTH.NEW_PASSWORD_SAME_AS_OLD'],
  INVALID_OLD_PASSWORD: ['NOTIFICATIONS.AUTH.INVALID_OLD_PASSWORD'],
  INVALID_PASSWORD_FORMAT: ['NOTIFICATIONS.AUTH.INVALID_PASSWORD_FORMAT'],
  PREV_PASSWORD_NOT_ALLOWED: ['NOTIFICATIONS.AUTH.PREV_PASSWORD_NOT_ALLOWED'],
  FIRST_STEP_NEEDED: ['NOTIFICATIONS.MESSAGES.FIRST_STEP_NEEDED'],
  FIRST_STEP_ASSET_NEEDED: ['NOTIFICATIONS.MESSAGES.FIRST_STEP_ASSET_NEEDED'],
  DEAL_NOT_STARTED: ['NOTIFICATIONS.MESSAGES.DEAL_NOT_STARTED'],
  INCOMPLETE_FORM: ['NOTIFICATIONS.GENERAL.INCOMPLETE_FORM'],
  INCOMPLETE_FORM_TERMS_NOT_SELECTED: ['NOTIFICATIONS.AUTH.INCOMPLETE_FORM_TERMS_NOT_SELECTED'],
  INCOMPLETE_FORM_ALL_TERMS_NOT_SELECTED: ['NOTIFICATIONS.MESSAGES.INCOMPLETE_FORM_ALL_TERMS_NOT_SELECTED'],
  TERMS_NOT_SELECTED: ['NOTIFICATIONS.AUTH.TERMS_NOT_SELECTED'],
  ALL_TERMS_NOT_SELECTED: ['NOTIFICATIONS.MESSAGES.ALL_TERMS_NOT_SELECTED'],
  NO_INVESTOR_OPTION_SELECTED: ['NOTIFICATIONS.MESSAGES.NO_INVESTOR_OPTION_SELECTED'],
  INVALID_FORM: ['NOTIFICATIONS.GENERAL.INVALID_FORM'],
  ADDRESS_INVALID_FORM: ['NOTIFICATIONS.GENERAL.ADDRESS_INVALID_FORM'],
  UPLOAD_VIDEO_DENIEND: ['NOTIFICATIONS.MESSAGES.UPLOAD_VIDEO_DENIEND'],
  INVALID_COLLECTION_ENTITY: ['NOTIFICATIONS.GENERAL.INVALID_COLLECTION_ENTITY'],

  ASSET_NOT_VISIBLE: ['NOTIFICATIONS.MESSAGES.ASSET_NOT_VISIBLE'],
  ASSET_NOT_PUBLISHED: ['NOTIFICATIONS.MESSAGES.ASSET_NOT_PUBLISHED'],

  INVALID_FORMAT: ['NOTIFICATIONS.FILE.INVALID_FORMAT'],
  FILE_SIZE: ['NOTIFICATIONS.FILE.FILE_SIZE'],
  FILE_ERROR: ['NOTIFICATIONS.FILE.FILE_ERROR'],
  NO_PREVIEW_FORMAT: ['NOTIFICATIONS.FILE.NO_PREVIEW_FORMAT'],
  ERROR_MAX_FILE_SIZE: ['NOTIFICATIONS.FILE.ERROR_MAX_FILE_SIZE'],
  DOCUMENT_DOWNLOAD: ['NOTIFICATIONS.FILE.DOCUMENT_DOWNLOAD'],
  ERROR_DOCUMENT_DOWNLOAD: ['NOTIFICATIONS.FILE.ERROR_DOCUMENT_DOWNLOAD'],
  REMOVE_FILE: ['NOTIFICATIONS.FILE.REMOVE_FILE'],
  ERROR_REMOVE_FILE: ['NOTIFICATIONS.FILE.ERROR_REMOVE_FILE'],
  COVER_IMAGE: ['NOTIFICATIONS.FILE.COVER_IMAGE'],
  FILE_UPLOADED: ['NOTIFICATIONS.FILE.FILE_UPLOADED'],
  ERROR_ADD_LOCAL_PARTNER: ['NOTIFICATIONS.MESSAGES.ERROR_ADD_LOCAL_PARTNER'],
  FILES_ORDER_CHANGED: ['NOTIFICATIONS.FILE.FILES_ORDER_CHANGED'],
  ERROR_FILES_ORDER: ['NOTIFICATIONS.FILE.ERROR_FILES_ORDER'],

  NO_CHANGES: ['NOTIFICATIONS.GENERAL.NO_CHANGES'],
  SUCCESS_CHANGE: ['NOTIFICATIONS.GENERAL.SUCCESS_CHANGE'],

  TOGGLE_VISIBILITY: ['NOTIFICATIONS.MESSAGES.TOGGLE_VISIBILITY'],
  ERROR_TOGGLE_VISIBILITY_CONFLICT: ['NOTIFICATIONS.MESSAGES.ERROR_TOGGLE_VISIBILITY_CONFLICT'],
  ERROR_TOGGLE_VISIBILITY_TRUE: ['NOTIFICATIONS.MESSAGES.ERROR_TOGGLE_VISIBILITY_TRUE'],
  ERROR_TOGGLE_VISIBILITY_FALSE: ['NOTIFICATIONS.MESSAGES.ERROR_TOGGLE_VISIBILITY_FALSE'],
  MAXIMUM_LIVE_PROJECTS_EXCEEDED: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_MAXIMUM_LIVE_PROJECTS_EXCEEDED'],
  ERROR_ORGANISATION_ARCHIVED: ['NOTIFICATIONS.PROJECT.ERROR_ORGANISATION_ARCHIVED'],

  CHANGE_PERMISSION: ['NOTIFICATIONS.AUTH.CHANGE_PERMISSION'],
  CHANGE_PERMISSION_ERROR: ['NOTIFICATIONS.AUTH.CHANGE_PERMISSION_ERROR'],
  CHANGE_VETTED_ERROR: ['NOTIFICATIONS.AUTH.CHANGE_VETTED_ERROR'],
  USER_WAS_ARCHIVED: ['NOTIFICATIONS.AUTH.USER_WAS_ARCHIVED'],
  USER_WAS_RESTORED: ['NOTIFICATIONS.AUTH.USER_WAS_RESTORED'],
  DELETE_USER: ['NOTIFICATIONS.AUTH.DELETE_USER'],
  DELETE_USER_ERROR: ['NOTIFICATIONS.AUTH.DELETE_USER_ERROR'],
  DELETE_USER_CONFLICT: ['NOTIFICATIONS.AUTH.DELETE_USER_CONFLICT'],
  DELETE_USER_SPONSOR_ACTIVE: ['NOTIFICATIONS.AUTH.DELETE_USER_SPONSOR_ACTIVE'],
  DELETE_USER_LINKED: ['NOTIFICATIONS.AUTH.DELETE_USER_LINKED'],

  IMAGE_FORMAT_ERROR: ['NOTIFICATIONS.FILE.IMAGE_FORMAT_ERROR'],
  LOAD_IMG_IN_CROPPER_ERROR: ['NOTIFICATIONS.FILE.LOAD_IMG_IN_CROPPER_ERROR'],
  UPDATE_PROFILE_IMG: ['NOTIFICATIONS.FILE.UPDATE_PROFILE_IMG'],
  UPDATE_PROFILE_IMG_ERROR: ['NOTIFICATIONS.FILE.UPDATE_PROFILE_IMG_ERROR'],
  ROLE_CHANGE_INVALID: ['NOTIFICATIONS.AUTH.ROLE_CHANGE_INVALID'],
  PARTNER_ALREADY_ASSIGNED: ['NOTIFICATIONS.MESSAGES.PARTNER_ALREADY_ASSIGNED'],
  PROJECT_WATCHLIST_ADD: ['NOTIFICATIONS.PROJECT.PROJECT_WATCHLIST_ADD'],
  PROJECT_WATCHLIST_REMOVE: ['NOTIFICATIONS.PROJECT.PROJECT_WATCHLIST_REMOVE'],
  ORGANIZATION_WATCHLIST_ADD: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_WATCHLIST_ADD'],
  ORGANIZATION_WATCHLIST_REMOVE: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_WATCHLIST_REMOVE'],
  CONTACT_COMPANY: ['NOTIFICATIONS.MESSAGES.CONTACT_COMPANY'],
  SURVEY: ['NOTIFICATIONS.SURVEY.SURVEY'],

  QUESTION_POSTED: ['NOTIFICATIONS.QUESTION.QUESTION_POSTED'],
  QUESTION_EDITED: ['NOTIFICATIONS.QUESTION.QUESTION_EDITED'],
  QUESTION_MOVED: ['NOTIFICATIONS.QUESTION.QUESTION_MOVED'],
  QUESTION_DELETED: ['NOTIFICATIONS.QUESTION.QUESTION_DELETED'],
  ANSWER_POSTED: ['NOTIFICATIONS.QUESTION.ANSWER_POSTED'],
  ANSWER_EDITED: ['NOTIFICATIONS.QUESTION.ANSWER_EDITED'],

  UPDATE_PUBLISHED: ['NOTIFICATIONS.UPDATE.UPDATE_PUBLISHED'],
  UPDATE_SAVED: ['NOTIFICATIONS.UPDATE.UPDATE_SAVED'],
  UPDATE_DELETE_SUCCESS: ['NOTIFICATIONS.UPDATE.UPDATE_DELETE_SUCCESS'],
  UPDATE_MISSING_REQUIRED_FIELDS: ['NOTIFICATIONS.UPDATE.UPDATE_MISSING_REQUIRED_FIELDS'],
  UPDATE_NO_COMPANY_ADMIN: ['NOTIFICATIONS.UPDATE.UPDATE_NO_COMPANY_ADMIN'],

  INVITATION_RESEND_SUCCESS: ['NOTIFICATIONS.INVITATION.INVITATION_RESEND_SUCCESS'],
  INVITATION_DELETED_SUCCESS: ['NOTIFICATIONS.INVITATION.INVITATION_DELETED_SUCCESS'],
  INVITE_USERS_ALREADY_INVITED: ['NOTIFICATIONS.INVITATION.INVITE_USERS_ALREADY_INVITED'],
  INVITATION_DELETE_ERROR: ['NOTIFICATIONS.INVITATION.INVITATION_DELETE_ERROR'],

  UPLOAD_IN_PROGRESS: ['NOTIFICATIONS.MESSAGES.UPLOAD_IN_PROGRESS'],
  TOKEN_NOT_FOUND: ['NOTIFICATIONS.AUTH.TOKEN_NOT_FOUND'],
  ALREADY_REGISTERED: ['NOTIFICATIONS.AUTH.ALREADY_REGISTERED'],
  COPIED_TO_CLIPBOARD: ['NOTIFICATIONS.GENERAL.COPIED_TO_CLIPBOARD'],

  ORGANIZATION_ADDED: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_ADDED'],
  ORGANIZATION_UPDATED: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_UPDATED'],
  ORGANIZATION_DELETED: ['NOTIFICATIONS.ORGANIZATION.DELETE_SUCCESS'],
  ORGANIZATION_TOGGLE_VISIBLITY: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_SUCCESS'],
  ORGANIZATION_TOGGLE_VISIBILITY_ERROR_INCOMPLETE: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_ERROR_INCOMPLETE'],
  ORGANIZATION_TOGGLE_VISIBILITY_ERROR_FINANCIAL_STATEMENTS: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_ERROR_FINANCIAL_STATEMENTS'],
  ORGANIZATION_TOGGLE_VISIBILITY_ERROR_PUBLISH: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_ERROR_PUBLISH'],
  ORGANIZATION_TOGGLE_VISIBILITY_ERROR_WITHDRAW: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_ERROR_WITHDRAW'],
  ORGANIZATION_TOGGLE_VISIBILITY_ERROR_PUBLISHED_PROJECTS: ['NOTIFICATIONS.ORGANIZATION.TOGGLE_VISIBILITY_ERROR_PUBLISHED_PROJECTS'],
  ORGANIZATION_DELETE_ERROR_PUBLISHED_PROJECTS: ['NOTIFICATIONS.ORGANIZATION.DELETE_ERROR_PUBLISHED_PROJECTS'],
  ORGANIZATION_DELETE_ERROR: ['NOTIFICATIONS.ORGANIZATION.DELETE_ERROR'],
  ORGANIZATION_PRIVATE_SIGN_IN: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_PRIVATE_SIGN_IN'],
  ORGANIZATION_PRIVATE_USER_NOT_VETTED: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_PRIVATE_USER_NOT_VETTED'],
  DUPLICATED_PARTNER_ORGANISATIONS: ['NOTIFICATIONS.ORGANIZATION.DUPLICATED_PARTNER_ORGANISATIONS'],
  ORGANIZATION_NAME_REQUIRED: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_NAME_REQUIRED'],
  ORGANIZATION_NAME_REQUIRED_UPLOAD: ['Organisation name is required in order to make an upload'],
  ORGANISATION_SETTINGS_SAVED: ['NOTIFICATIONS.ORGANIZATION.ORGANISATION_SETTINGS_SAVED'],
  ORGANIZATION_NOT_FOUND: ['NOTIFICATIONS.ORGANIZATION.ORGANIZATION_NOT_FOUND'],
  MAXIMUM_NUMBER_OF_LIVE_PROJECTS_EXCEEDED: ['NOTIFICATIONS.ORGANIZATION.MAXIMUM_NUMBER_OF_LIVE_PROJECTS_EXCEEDED'],

  PROJECT_ADDED: ['NOTIFICATIONS.PROJECT.PROJECT_ADDED'],
  PROJECT_UPDATED: ['NOTIFICATIONS.PROJECT.PROJECT_UPDATED'],
  PROJECT_SCORE_UPDATED: ['NOTIFICATIONS.PROJECT.PROJECT_SCORE_UPDATED'],
  PROJECT_VISIBILITY_UPDATED: ['NOTIFICATIONS.PROJECT.PROJECT_VISIBILITY_UPDATED'],
  PROJECT_MANAGER_HAS_INCOMPLETE_PROFILE: ['NOTIFICATIONS.PROJECT.PROJECT_MANAGER_HAS_INCOMPLETE_PROFILE'],
  PROJECT_DELETED: ['NOTIFICATIONS.PROJECT.PROJECT_DELETED'],
  PROJECT_PRIVATE_SIGN_IN: ['NOTIFICATIONS.PROJECT.PROJECT_PRIVATE_SIGN_IN'],
  PROJECT_PRIVATE_USER_NOT_VETTED: ['NOTIFICATIONS.PROJECT.PROJECT_PRIVATE_USER_NOT_VETTED'],
  PROJECT_ERROR_DUPLICATE_GOALS: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_DUPLICATE_GOALS'],
  PROJECT_ERROR_MANAGER_INCOMPLETE_PROFILE: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_MANAGER_INCOMPLETE_PROFILE'],
  PROJECT_ERROR_UPDATE: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_UPDATE'],
  PROJECT_TOGGLE_VISIBILITY: ['NOTIFICATIONS.PROJECT.PROJECT_TOGGLE_VISIBILITY'],
  PROJECT_ERROR_TOGGLE_VISIBILITY_CONFLICT: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_TOGGLE_VISIBILITY_CONFLICT'],
  PROJECT_ERROR_TOGGLE_VISIBILITY_ORGANIZATION: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_TOGGLE_VISIBILITY_ORGANIZATION'],
  PROJECT_ERROR_TOGGLE_VISIBILITY_TRUE: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_TOGGLE_VISIBILITY_TRUE'],
  PROJECT_ERROR_TOGGLE_VISIBILITY_FALSE: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_TOGGLE_VISIBILITY_FALSE'],
  FORM_WAITING_FOR_APPROVAL: ['NOTIFICATIONS.GENERAL.FORM_WAITING_FOR_APPROVAL'],
  PROJECT_NAME_REQUIRED: ['NOTIFICATIONS.PROJECT.PROJECT_NAME_REQUIRED'],
  PROJECT_NAME_REQUIRED_UPLOAD: ['NOTIFICATIONS.PROJECT.PROJECT_NAME_REQUIRED_UPLOAD'],
  PROJECT_ERROR_END_DATE: ['NOTIFICATIONS.PROJECT.PROJECT_ERROR_END_DATE'],

  GIVING_FORM_SUCCESS: ['NOTIFICATIONS.GIVING.GIVING_FORM_SUCCESS'],
  GIVING_FORM_ERROR: ['NOTIFICATIONS.GIVING.GIVING_FORM_ERROR'],
  GIVING_LINK_MISSING_ERROR: ['NOTIFICATIONS.GIVING.GIVING_LINK_MISSING_ERROR'],

  GROUP_ADDED: ['NOTIFICATIONS.GROUP.GROUP_ADDED'],
  GROUP_UPDATED: ['NOTIFICATIONS.GROUP.GROUP_UPDATED'],
  GROUP_CREATE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_CREATE_ERROR'],
  GROUP_UPDATE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_UPDATE_ERROR'],
  GROUP_PROJECTS_UPDATED: ['NOTIFICATIONS.GROUP.GROUP_PROJECTS_UPDATED'],
  GROUP_PROJECTS_UPDATE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_PROJECTS_UPDATE_ERROR'],
  GROUP_ORGANISATIONS_UPDATED: ['NOTIFICATIONS.GROUP.GROUP_ORGANISATIONS_UPDATED'],
  GROUP_ORGANISATIONS_UPDATE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_ORGANISATIONS_UPDATE_ERROR'],
  GROUP_PORTFOLIOS_UPDATED: ['NOTIFICATIONS.GROUP.GROUP_PORTFOLIOS_UPDATED'],
  GROUP_PORTFOLIOS_UPDATE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_PORTFOLIOS_UPDATE_ERROR'],
  GROUP_DELETED: ['NOTIFICATIONS.GROUP.GROUP_DELETED'],
  GROUP_DELETE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_DELETE_ERROR'],
  GROUP_ARCHIVED: ['NOTIFICATIONS.GROUP.GROUP_ARCHIVED'],
  GROUP_ARCHIVE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_ARCHIVE_ERROR'],
  GROUP_UNARCHIVED: ['NOTIFICATIONS.GROUP.GROUP_UNARCHIVED'],
  GROUP_UNARCHIVE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_UNARCHIVE_ERROR'],
  GROUP_APPROVED: ['NOTIFICATIONS.GROUP.GROUP_APPROVED'],
  GROUP_DECLINED: ['NOTIFICATIONS.GROUP.GROUP_DECLINED'],

  GROUP_MEMBERS_ALREADY_INVITED: ['NOTIFICATIONS.GROUP.MEMBERS_ALREADY_INVITED'],
  GROUP_MEMBER_ADDED: ['NOTIFICATIONS.GROUP.MEMBER_ADDED'],
  GROUP_MEMBER_UPDATED: ['NOTIFICATIONS.GROUP.MEMBER_UPDATED'],
  GROUP_MEMBER_REMOVED: ['NOTIFICATIONS.GROUP.MEMBER_REMOVED'],
  GROUP_MEMBER_UPDATE_ERROR: ['NOTIFICATIONS.GROUP.MEMBER_UPDATE_ERROR'],

  GROUP_UPDATE_DELETED: ['NOTIFICATIONS.GROUP.GROUP_UPDATE_DELETED'],
  GROUP_UPDATE_DELETE_ERROR: ['NOTIFICATIONS.GROUP.GROUP_UPDATE_DELETE_ERROR'],
  JOIN_REQUEST_SENT: ['NOTIFICATIONS.GROUP.JOIN_REQUEST_SENT'],
  JOINED_GROUP: ['NOTIFICATIONS.GROUP.JOINED_GROUP'],
  LEFT_GROUP: ['NOTIFICATIONS.GROUP.LEFT_GROUP'],
  INVITATION_REJECTED: ['NOTIFICATIONS.GROUP.INVITATION_REJECTED'],
  JOIN_REQUEST_CANCELED: ['NOTIFICATIONS.GROUP.JOIN_REQUEST_CANCELED'],
  ACTION_ERROR: ['NOTIFICATIONS.GROUP.ACTION_ERROR'],
  REPORT_SUBMITTED: ['NOTIFICATIONS.GROUP.REPORT_SUBMITTED'],

  PROFILE_CREATED_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_CREATED_SUCCESS'],
  PROFILE_UPDATED_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_UPDATED_SUCCESS'],
  PROFILE_SET_DEFAULT_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_SET_DEFAULT_SUCCESS'],
  PROFILE_DELETE_ERROR_DEFAULT: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_DELETE_ERROR_DEFAULT'],
  PROFILE_DELETE_ERROR_USED: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_DELETE_ERROR_USED'],
  PROFILE_DELETE_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.PROFILE_DELETE_SUCCESS'],

  LANGUAGE_SAVE_DRAFT: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_SAVE_DRAFT'],
  LANGUAGE_SAVE_CHANGES: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_SAVE_CHANGES'],
  LANGUAGE_PUBLISH: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_PUBLISH'],
  LANGUAGE_UNPUBLISH: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_UNPUBLISH'],
  LANGUAGE_DELETE: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_DELETE'],
  LANGUAGE_SAVE_CHANGES_BEFORE_PUBLISH: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_CHANGES_BEFORE_PUBLISH'],
  LANGUAGE_SAVE_CHANGES_BEFORE_UNPUBLISH: ['NOTIFICATIONS.GLOBAL_SETTINGS.LANGUAGE_CHANGES_BEFORE_UNPUBLISH'],

  PORTFOLIO_ADDED: ['NOTIFICATIONS.PORTFOLIO.PORTFOLIO_ADDED'],
  PORTFOLIO_UPDATED: ['NOTIFICATIONS.PORTFOLIO.PORTFOLIO_UPDATED'],
  PORTFOLIO_DELETED: ['NOTIFICATIONS.PORTFOLIO.DELETE_SUCCESS'],
  PORTFOLIO_TOGGLE_VISIBLITY: ['NOTIFICATIONS.PORTFOLIO.TOGGLE_VISIBILITY_SUCCESS'],
  PORTFOLIO_TOGGLE_VISIBILITY_ERROR_INCOMPLETE: ['NOTIFICATIONS.PORTFOLIO.TOGGLE_VISIBILITY_ERROR_INCOMPLETE'],
  PORTFOLIO_TOGGLE_VISIBILITY_ERROR_PUBLISH: ['NOTIFICATIONS.PORTFOLIO.TOGGLE_VISIBILITY_ERROR_PUBLISH'],
  PORTFOLIO_TOGGLE_VISIBILITY_ERROR_WITHDRAW: ['NOTIFICATIONS.PORTFOLIO.TOGGLE_VISIBILITY_ERROR_WITHDRAW'],
  PORTFOLIO_TOGGLE_VISIBILITY_ERROR_PUBLISHED_PROJECTS: ['NOTIFICATIONS.PORTFOLIO.TOGGLE_VISIBILITY_ERROR_PUBLISHED_PROJECTS'],
  PORTFOLIO_DELETE_ERROR_PUBLISHED_PROJECTS: ['NOTIFICATIONS.PORTFOLIO.DELETE_ERROR_PUBLISHED_PROJECTS'],
  PORTFOLIO_DELETE_ERROR: ['NOTIFICATIONS.PORTFOLIO.DELETE_ERROR'],
  PORTFOLIO_NAME_REQUIRED: ['NOTIFICATIONS.PORTFOLIO.PORTFOLIO_NAME_REQUIRED'],

  GLOBAL_CONFIG_SAVED_CHANGES_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.GLOBAL_CONFIG_SAVED_CHANGES_SUCCESS'],
  HOMEPAGE_FOCUS_SAVED_SUCCESS: ['NOTIFICATIONS.GLOBAL_SETTINGS.HOMEPAGE_FOCUS_CHANGES_SAVED_SUCCESS'],

  CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION'],
  CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION_BUT_INCOMPLETE: [
    'NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_SUCCESSFULLY_ADDED_AS_CHAMPION_BUT_INCOMPLETE',
  ],
  CHAMPION_ADMIN_REMOVED_FROM_ORGANISATION: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_FROM_ORGANISATION'],
  CHAMPION_ADMIN_REMOVED_FROM_PROJECT: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_FROM_PROJECT'],
  CHAMPION_ADMIN_REMOVED_FROM_PORTFOLIO: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_FROM_PORTFOLIO'],
  CHAMPION_ADMIN_SUCCESSFULLY_UPDATED_INFORMATION: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_SUCCESSFULLY_UPDATED_INFORMATION'],
  CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_PROJECT: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_PROJECT'],
  CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_ORGANISATION: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_ORGANISATION'],
  CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_PORTFOLIO: ['NOTIFICATIONS.CHAMPION.CHAMPION_ADMIN_REMOVED_HIMSELF_FROM_PORTFOLIO'],

  BADGE_SUCCESSFULLY_CREATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.BADGE_SUCCESSFULLY_CREATED'],
  BADGE_SUCCESSFULLY_UPDATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.BADGE_SUCCESSFULLY_UPDATED'],
  BADGE_SUCCESSFULLY_DELETED: ['NOTIFICATIONS.GLOBAL_SETTINGS.BADGE_SUCCESSFULLY_DELETED'],

  REGION_NAME_REQUIRED: ['NOTIFICATIONS.REGION.REGION_NAME_REQUIRED'],
  REGION_SUCCESSFULLY_DELETED: ['NOTIFICATIONS.GLOBAL_SETTINGS.REGION_SUCCESSFULLY_DELETED'],
  REGION_HAS_COUNTRY: ['NOTIFICATIONS.GLOBAL_SETTINGS.REGION_HAS_COUNTRY'],
  REGION_SUCCESSFULLY_CREATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.REGION_SUCCESSFULLY_CREATED'],
  REGION_SUCCESSFULLY_UPDATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.REGION_SUCCESSFULLY_UPDATED'],

  CAUSE_NAME_REQUIRED: ['NOTIFICATIONS.CAUSE.CAUSE_NAME_REQUIRED'],
  CAUSE_SUCCESSFULLY_DELETED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CAUSE_SUCCESSFULLY_DELETED'],
  CAUSE_SUCCESSFULLY_CREATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CAUSE_SUCCESSFULLY_CREATED'],
  CAUSE_SUCCESSFULLY_UPDATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CAUSE_SUCCESSFULLY_UPDATED'],

  CATEGORY_NAME_REQUIRED: ['NOTIFICATIONS.CATEGORY.CATEGORY_NAME_REQUIRED'],
  CATEGORY_SUCCESSFULLY_DELETED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CATEGORY_SUCCESSFULLY_DELETED'],
  CATEGORY_SUCCESSFULLY_CREATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CATEGORY_SUCCESSFULLY_CREATED'],
  CATEGORY_SUCCESSFULLY_UPDATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.CATEGORY_SUCCESSFULLY_UPDATED'],

  HELP_VIDEO_SUCCESSFULLY_CREATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.HELP_VIDEO_SUCCESSFULLY_CREATED'],
  HELP_VIDEO_SUCCESSFULLY_UPDATED: ['NOTIFICATIONS.GLOBAL_SETTINGS.HELP_VIDEO_SUCCESSFULLY_UPDATED'],
  HELP_VIDEO_SUCCESSFULLY_DELETED: ['NOTIFICATIONS.GLOBAL_SETTINGS.HELP_VIDEO_SUCCESSFULLY_DELETED'],
};
